<template>
  <div class="content" style="min-height: 100vh">
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="main">
      <div class="title">
        <div @click="back">
          <van-image
            width="9px"
            height="15px"
            :src="require('@/assets/cls/arrow-l.png')"
          />
          <span>{{ $t("Activities.return") }}</span>
        </div>
      </div>
      <div class="btn">
        <div :class="4 == i ? 'current' : ''" @click="screenHandle('XX', 4)">
          {{ $t("Activities.invite") }}
        </div>
        <div :class="2 == i ? 'current' : ''" @click="screenHandle('XX', 2)">
          {{ $t("Activities.mining") }}
        </div>
        <div :class="3 == i ? 'current' : ''" @click="screenHandle('XX', 3)">
          {{ $t("Activities.answer") }}
        </div>
        <div :class="1 == i ? 'current' : ''" @click="screenHandle('USDT', 1)">
          {{ $t("Activities.stake") }}
        </div>
      </div>
      <div class="activitiestext">
        <p>{{ $t("Activities.Activities") }}</p>
        <van-image
          @click="popupShow = true"
          width="15px"
          height="15px"
          :src="require('@/assets/cls/screen.png')"
        />
      </div>
      <!-- 列表 -->
      <div class="record" v-if="i == 1">
        <div class="record_top">
          <div>{{ $t("Activities.time") }}</div>
          <div>{{ $t("Activities.operate") }}</div>
          <div>{{ $t("Activities.num") }}</div>
          <div style="text-align: right; padding-right: 10px">
            {{ $t("Activities.class") }}
          </div>
        </div>
        <van-pull-refresh
          style="height: 561px; overflow: scroll"
          :success-text="$t('activeRecord.refresh')"
          :pulling-text="$t('activeRecord.refreshsl')"
          :loosing-text="$t('activeRecord.refreshs')"
          :loading-text="$t('public.loading')"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('activeRecord.NoMore')"
            :loading-text="$t('public.loading')"
            @load="onLoad"
            class="list"
            :immediate-check="false"
            :offset="100"
          >
            <!-- <div class="record_center" v-if="pledge_Recprd24h">
              <div>{{ pledge_Recprd24h.updated_at }}</div>
              <div>{{ pledge_Recprd24h.type }}</div>
              <div>{{ pledge_Recprd24h.amount }}</div>
              <div v-if="pledge_Recprd24h.pledge_type" style="text-align: right; padding-right: 10px">{{pledge_Recprd24h.pledge_type == 1?'24小时':'30天'}}</div>
              <div v-else style="text-align: right; padding-right: 10px"></div>
            </div> -->
            <div
              class="record_center"
              v-for="(item, index) in pledge_Recprd72h"
              :key="index"
            >
              <div>{{ funDate(item.updated_at) }}</div>
              <div>{{ item.type }}</div>
              <div>{{ item.amount }}</div>
              <div style="text-align: right; padding-right: 10px">
                {{
                  item.pledge_type == 1 ? $t("Activities.7days") : (item.pledge_type == 2 ? $t("Activities.15days") : $t("Activities.day4"))
                }}
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>

      <div v-if="i == 2">
        <van-pull-refresh
          style="height: 600px; overflow: scroll"
          :success-text="$t('activeRecord.refresh')"
          :pulling-text="$t('activeRecord.refreshsl')"
          :loosing-text="$t('activeRecord.refreshs')"
          :loading-text="$t('public.loading')"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('activeRecord.NoMore')"
            :loading-text="$t('public.loading')"
            @load="onLoad"
            class="list"
            :immediate-check="false"
            :offset="100"
          >
            <div class="mining">
              <div
                class="mining_item"
                v-for="(item, index) in mining_Record"
                :key="index"
              >
                <div class="items">
                  <p>{{ $t("Activities.time") }}</p>
                  <div>{{ funDate(item.created_at) }}</div>
                </div>
                <div class="items">
                  <p>{{ $t("Activities.operate") }}</p>
                  <div>{{ item.type }}</div>
                </div>
                <div class="items">
                  <p>{{ $t("Activities.num") }}</p>
                  <div>{{ item.amount }}</div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>

      <div v-if="i == 3">
        <van-pull-refresh
          style="height: 600px; overflow: scroll"
          :success-text="$t('activeRecord.refresh')"
          :pulling-text="$t('activeRecord.refreshsl')"
          :loosing-text="$t('activeRecord.refreshs')"
          :loading-text="$t('public.loading')"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('activeRecord.NoMore')"
            :loading-text="$t('public.loading')"
            @load="onLoad"
            class="list"
            :immediate-check="false"
            :offset="100"
          >
            <div class="mining">
              <div
                class="mining_item"
                v-for="(item, index) in answer_Record"
                :key="index"
              >
                <div class="items">
                  <p>{{ $t("Activities.time") }}</p>
                  <div style="width: 70px">{{ funDate(item.created_at) }}</div>
                </div>
                <div class="items">
                  <p>{{ $t("Activities.operate") }}</p>
                  <div>
                    {{
                      item.operate == 1
                        ? $t("Activities.Obtaining")
                        : $t("Activities.participate")
                    }}
                  </div>
                </div>
                <div class="items">
                  <p>{{ $t("Activities.class") }}</p>
                  <div v-if="item.type == 24 || item.type == 26">
                    {{ $t("Activities.AnswerPass") }}
                  </div>
                  <div v-if="item.type == 25 || item.type == 27">
                    {{ $t("Activities.Answerlevels") }}
                  </div>
                </div>
                <div class="items" v-if="item.operate == 1">
                  <p style="text-align: right">{{ $t("Activities.reward") }}</p>
                  <div style="text-align: right" v-if="item.type == 24">
                    {{ item.amount + "coins" }}
                  </div>
                  <div style="text-align: right" v-if="item.type == 25">
                    {{ $t("Activities.miningCard") }}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div v-if="i == 4">
        <van-pull-refresh
          style="height: 600px; overflow: scroll"
          :success-text="$t('activeRecord.refresh')"
          :pulling-text="$t('activeRecord.refreshsl')"
          :loosing-text="$t('activeRecord.refreshs')"
          :loading-text="$t('public.loading')"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('activeRecord.NoMore')"
            :loading-text="$t('public.loading')"
            @load="onLoad"
            class="list"
            :immediate-check="false"
            :offset="100"
          >
            <div>
              <div v-for="(item, index) in inviter_Record" :key="index">
                <div>
                  <div>
                    <div class="hangbiaos">
                      <div class="biaos"></div>
                      {{ item.updated_at }}
                    </div>
                    <div class="biaosTow">
                      {{ item.type }}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 筛选弹框列 -->
    <van-popup class="screen-popup" v-model="popupShow" position="bottom" round>
      <div class="title">{{ $t("activeRecord.screening") }}</div>
      <div class="pledge" v-if="i == 1">
        <div class="pledge_item">
          <p>{{ $t("Activities.time") }}</p>
          <div class="pledge_tab">
            <div
              @click="onPledge(1, 'date', 1)"
              :class="1 == active.pledge_item[0].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.day1") }}
            </div>
            <div
              @click="onPledge(2, 'date', 2)"
              :class="2 == active.pledge_item[0].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.day2") }}
            </div>
            <div
              @click="onPledge(3, 'date', 3)"
              :class="3 == active.pledge_item[0].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.day3") }}
            </div>
          </div>
          <p>{{ $t("Activities.operate") }}</p>
          <div class="pledge_tab">
            <div
              @click="onPledge(1, 'operate', 1)"
              :class="1 == active.pledge_item[1].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.stake") }}
            </div>
            <div
              @click="onPledge(2, 'operate', 2)"
              :class="2 == active.pledge_item[1].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.redeem") }}
            </div>
            <div
              @click="onPledge(3, 'operate', 3)"
              :class="3 == active.pledge_item[1].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.income") }}
            </div>
          </div>
          <p>{{ $t("Activities.class") }}</p>
          <div class="pledge_tab">
            <div
              @click="onPledge(1, 'typesOf', 1)"
              :class="1 == active.pledge_item[2].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.7days") }}
            </div>
            <div
              @click="onPledge(2, 'typesOf', 2)"
              :class="2 == active.pledge_item[2].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.15days") }}
            </div>
            <div
              @click="onPledge(3, 'typesOf', 3)"
              :class="3 == active.pledge_item[2].pledgeActive ? 'active' : ''"
            >
              {{ $t("Activities.day4") }}
            </div>
          </div>
        </div>
        <div class="pledge_btn">
          <div @click="popupShow = false">
            {{ $t("Activities.cancellation") }}
          </div>
          <div style="background: #8f93fb; color: white" @click="sub('zy')">
            {{ $t("Activities.determine") }}
          </div>
        </div>
      </div>
      <div class="pledge" v-if="i == 2">
        <div class="pledge_item">
          <p>{{ $t("Activities.time") }}</p>
          <div class="pledge_tab">
            <div
              @click="onMining(1, 'date', 1)"
              :class="1 == active.mining_item[0].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.day1") }}
            </div>
            <div
              @click="onMining(2, 'date', 2)"
              :class="2 == active.mining_item[0].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.day2") }}
            </div>
            <div
              @click="onMining(3, 'date', 3)"
              :class="3 == active.mining_item[0].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.day3") }}
            </div>
          </div>
          <p>{{ $t("Activities.operate") }}</p>
          <div class="pledge_tab">
            <div
              @click="onMining(4, 'operate', 1)"
              :class="4 == active.mining_item[1].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.purchase") }}
            </div>
            <div
              @click="onMining(5, 'operate', 2)"
              :class="5 == active.mining_item[1].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.damage") }}
            </div>
            <div
              @click="onMining(6, 'operate', 3)"
              :class="6 == active.mining_item[1].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.ExtractIncome") }}
            </div>
          </div>
          <div class="pledge_tab">
            <div
              @click="onMining(7, 'operate', 4)"
              :class="7 == active.mining_item[1].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.AutomaticCard") }}
            </div>
            <div
              @click="onMining(8, 'operate', 5)"
              :class="8 == active.mining_item[1].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.StartMining") }}
            </div>
            <div
              @click="onMining(9, 'operate', 0)"
              :class="9 == active.mining_item[1].miningActive ? 'active' : ''"
            >
              {{ $t("Activities.all") }}
            </div>
          </div>
        </div>
        <div class="pledge_btn">
          <div @click="popupShow = false">
            {{ $t("Activities.cancellation") }}
          </div>
          <div style="background: #8f93fb; color: white" @click="sub('wk')">
            {{ $t("Activities.determine") }}
          </div>
        </div>
      </div>

      <div class="pledge" v-if="i == 3">
        <div class="pledge_item">
          <p>{{ $t("Activities.time") }}</p>
          <div class="pledge_tab">
            <div
              @click="onAnswer(1, 'date', 1)"
              :class="1 == active.answer_item[0].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.day1") }}
            </div>
            <div
              @click="onAnswer(2, 'date', 2)"
              :class="2 == active.answer_item[0].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.day2") }}
            </div>
            <div
              @click="onAnswer(3, 'date', 3)"
              :class="3 == active.answer_item[0].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.day3") }}
            </div>
          </div>
          <p>{{ $t("Activities.operate") }}</p>
          <div class="pledge_tab">
            <div
              style="flex: 0.48"
              @click="onAnswer(4, 'operate', 1)"
              :class="4 == active.answer_item[1].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.Obtaining") }}
            </div>
            <div
              style="flex: 0.48"
              @click="onAnswer(5, 'operate', 2)"
              :class="5 == active.answer_item[1].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.participate") }}
            </div>
          </div>
          <p>{{ $t("Activities.class") }}</p>
          <div class="pledge_tab">
            <div
              style="flex: 0.48"
              @click="onAnswer(6, 'typesOf', 1)"
              :class="6 == active.answer_item[2].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.AnswerPass") }}
            </div>
            <div
              style="flex: 0.48"
              @click="onAnswer(7, 'typesOf', 2)"
              :class="7 == active.answer_item[2].answerActive ? 'active' : ''"
            >
              {{ $t("Activities.Answerlevels") }}
            </div>
          </div>
        </div>
        <div class="pledge_btn">
          <div @click="popupShow = false">
            {{ $t("Activities.cancellation") }}
          </div>
          <div style="background: #8f93fb; color: white" @click="sub('dt')">
            {{ $t("Activities.determine") }}
          </div>
        </div>
      </div>
      <div class="pledge" v-if="i == 4">
        <div class="pledge_item">
          <p>{{ $t("Activities.time") }}</p>
          <div class="pledge_tab">
            <div
              @click="onAnsweryq(1, 'date', 1)"
              :class="1 == active.inviter_item[0].inviterActive ? 'active' : ''"
            >
              {{ $t("Activities.day1") }}
            </div>
            <div
              @click="onAnsweryq(2, 'date', 2)"
              :class="2 == active.inviter_item[0].inviterActive ? 'active' : ''"
            >
              {{ $t("Activities.day2") }}
            </div>
            <div
              @click="onAnsweryq(3, 'date', 3)"
              :class="3 == active.inviter_item[0].inviterActive ? 'active' : ''"
            >
              {{ $t("Activities.day3") }}
            </div>
          </div>
          <p>{{ $t("Activities.class") }}</p>
          <div class="pledge_tab">
            <div
              style="flex: 0.48"
              @click="onAnsweryq(4, 'typesOf', 1)"
              :class="4 == active.inviter_item[1].inviterActive ? 'active' : ''"
            >
              {{ $t("Activities.invite") }}
            </div>
            <div
              style="flex: 0.48"
              @click="onAnsweryq(5, 'typesOf', 2)"
              :class="5 == active.inviter_item[1].inviterActive ? 'active' : ''"
            >
              {{ $t("Activities.Income") }}
            </div>
          </div>
        </div>
        <div class="pledge_btn">
          <div @click="popupShow = false">
            {{ $t("Activities.cancellation") }}
          </div>
          <div style="background: #8f93fb; color: white" @click="sub('yq')">
            {{ $t("Activities.determine") }}
          </div>
        </div>
      </div>
    </van-popup>
    <!-- <asidePopup @asideFlagShow="asideFlagShow" style="text-align:left" :asideFlag="asideFlag"></asidePopup> -->
    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components: { asidePopup },

  data() {
    return {
      active: {
        pledge_item: [
          { pledgeActive: 0 },
          { pledgeActive: 0 },
          { pledgeActive: 0 },
        ],
        mining_item: [{ miningActive: 0 }, { miningActive: 0 }],
        answer_item: [
          { answerActive: 0 },
          { answerActive: 0 },
          { answerActive: 0 },
        ],
        inviter_item: [
          { inviterActive: 0 },
          { inviterActive: 0 },
          { inviterActive: 0 },
        ],
      },
      type: [
        { key: 1, value: this.$t("Activities.type1") },
        { key: 2, value: this.$t("Activities.type2") },
        { key: 3, value: this.$t("Activities.type3") },
        { key: 4, value: this.$t("Activities.type4") },
        { key: 5, value: this.$t("Activities.type5") },
        { key: 6, value: this.$t("Activities.type6") },
        { key: 7, value: this.$t("Activities.type7") },
        { key: 8, value: this.$t("Activities.type8") },
        { key: 9, value: this.$t("Activities.type9") },
        { key: 10, value: this.$t("Activities.type10") },
        { key: 11, value: this.$t("Activities.type11") },
        { key: 12, value: this.$t("Activities.type12") },
        { key: 13, value: this.$t("Activities.type13") },
        { key: 14, value: this.$t("Activities.type14") },
        { key: 15, value: this.$t("Activities.type15") },
        { key: 18, value: this.$t("Activities.type18") },
        { key: 19, value: this.$t("Activities.type19") },
        { key: 20, value: this.$t("Activities.type20") },
        { key: 21, value: this.$t("Activities.type21") },
        { key: 22, value: this.$t("Activities.type22") },
        { key: 23, value: this.$t("Activities.type23") },
        { key: 24, value: this.$t("Activities.type24") },
        { key: 25, value: this.$t("Activities.type25") },
        { key: 26, value: this.$t("Activities.type26") },
        { key: 27, value: this.$t("Activities.type27") },
        { key: 28, value: this.$t("Activities.type28") },
        { key: 29, value: this.$t("Activities.type29") },
        { key: 30, value: this.$t("Activities.type30") },
      ],
      asideFlag: false,
      popupShow: false,
      screenList: [
        this.$t("activeRecord.all"),
        this.$t("activeRecord.pledge"),
        "NFT",
        this.$t("activeRecord.node"),
        "CLS",
        this.$t("activeRecord.income"),
        this.$t("activeRecord.TopUp"),
      ],
      currentScreen: 0,
      page: 1,
      list: [
        { w_type: 1, created_at: 2, msg: 21 },
        { w_type: 1, created_at: 2, msg: 21 },
        { w_type: 1, created_at: 2, msg: 21 },
      ],
      i: 4,
      loading: false, //加载
      finished: false, //加载结束
      refreshing: false, //下拉刷新
      pledge_Record: null,
      mining_Record: [],
      answer_Record: [],
      inviter_Record: [],
      pledge_Recprd24h: {},
      pledge_Recprd72h: [],
      objList: [
        { page: 1, time: 0, operate: 0, type: 0 },
        { page: 1, time: 0, operate: 0 },
        { page: 1, time: 0, operate: 0, typeT: 0 },
        { page: 1, time: 0, operate: 0, typeT: 0 },
      ],
    };
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    if (this.$route.query.type) {
      this.currentScreen = this.$route.query.type;
    }
    this.pledgeRecord();
    this.wak();
    this.answerRecord();
    // this.funDate();
    this.yqhd();
  },
  methods: {
    funDate(date) {
      let obj1 = date.split(" ");
      let obj2 = obj1[0].split("-");
      let dates = obj2[1] + "-" + obj2[2] + " " + obj1[1];
      return dates;
    },
    sub(type) {
      if (type == "dt") {
        this.answerRecord();
        this.popupShow = false;
      }
      if (type == "wk") {
        this.wak();
        this.popupShow = false;
      }
      if (type == "zy") {
        this.pledgeRecord();
        this.popupShow = false;
      }
      if (type == "yq") {
        this.yqhd();
        this.popupShow = false;
      }
    },
    //答题活动记录
    answerRecord(load = false) {
      this.$axios
        .get(this.$api.answerActivity, {
          page: this.objList[2].page,
          pageSize: 8,
          time: this.objList[2].time,
          operate: this.objList[2].operate,
          type: this.objList[2].typeT,
        })
        .then((res) => {
          if (load) {
            this.answer_Record = [...this.answer_Record, ...res.data.list];
            this.loading = false;
            if (!res.data.list.length) {
              this.finished = true;
            }
          } else {
            this.answer_Record = res.data.list;
            this.refreshing = false;
          }
        });
    },
    //邀请活动记录
    yqhd(load = false) {
      this.$axios
        .get(this.$api.inviterActiveity, {
          page: this.objList[3].page,
          pageSize: 10,
          time: this.objList[3].time,
          type: this.objList[3].operate,
        })
        .then((res) => {
          let type = [
            //data中的数据流有问题 所以在这里重新定义一遍
            { key: 1, value: this.$t("Activities.type1") },
            { key: 2, value: this.$t("Activities.type2") },
            { key: 3, value: this.$t("Activities.type3") },
            { key: 4, value: this.$t("Activities.type4") },
            { key: 5, value: this.$t("Activities.type5") },
            { key: 6, value: this.$t("Activities.type6") },
            { key: 7, value: this.$t("Activities.type7") },
            { key: 8, value: this.$t("Activities.type8") },
            { key: 9, value: this.$t("Activities.type9") },
            { key: 10, value: this.$t("Activities.type10") },
            { key: 11, value: this.$t("Activities.type11") },
            { key: 12, value: this.$t("Activities.type12") },
            { key: 13, value: this.$t("Activities.type13") },
            { key: 14, value: this.$t("Activities.type14") },
            { key: 15, value: this.$t("Activities.type15") },
            { key: 18, value: this.$t("Activities.type18") },
            { key: 19, value: this.$t("Activities.type19") },
            { key: 20, value: this.$t("Activities.type20") },
            { key: 21, value: this.$t("Activities.type21") },
            { key: 22, value: this.$t("Activities.type22") },
            { key: 23, value: this.$t("Activities.type23") },
            { key: 24, value: this.$t("Activities.type24") },
            { key: 25, value: this.$t("Activities.type25") },
            { key: 26, value: this.$t("Activities.type26") },
            { key: 27, value: this.$t("Activities.type27") },
            { key: 28, value: this.$t("Activities.type28") },
            { key: 29, value: this.$t("Activities.type29") },
            { key: 30, value: this.$t("Activities.type30") },
            { key: 31, value: this.$t("Activities.type31") },
            { key: 32, value: this.$t("Activities.type32") },
          ];

          if (load) {
            //数据拼接
            let list = JSON.parse(JSON.stringify(res.data.list));
            list.forEach((item) => {
              type.forEach((i) => {
                if (item.type == i.key) {
                  item.type = i.value;
                  if (i.key == 30) {
                    item.type = i.value + " " + item.amount + "BNB";
                  }
                  if (i.key == 12) {
                    item.type = i.value + " " + item.amount + "USDT";
                  }
                }
              });
            });
            this.inviter_Record = [...this.inviter_Record, ...list];
            //控制加载
            this.loading = false;
            if (!res.data.list.length) {
              this.finished = true;
            }
          } else {
            this.inviter_Record = res.data.list;
            this.inviter_Record.forEach((item) => {
              type.forEach((i) => {
                if (item.type == i.key) {
                  item.type = i.value;
                  if (i.key == 30) {
                    item.type = i.value + " " + item.amount + "BNB";
                  }
                  if (i.key == 12) {
                    item.type = i.value + " " + item.amount + "USDT";
                  }
                }
              });
            });
            this.refreshing = false;
          }
        });
    },
    //挖矿活动记录
    wak(load = false) {
      this.$axios
        .get(this.$api.mineActivity, {
          page: this.objList[1].page,
          pageSize: 10,
          time: this.objList[1].time,
          operate: this.objList[1].operate,
        })
        .then((res) => {
          let type = [
            //data中的数据流有问题 所以在这里重新定义一遍
            { key: 1, value: this.$t("Activities.type1") },
            { key: 2, value: this.$t("Activities.type2") },
            { key: 3, value: this.$t("Activities.type3") },
            { key: 4, value: this.$t("Activities.type4") },
            { key: 5, value: this.$t("Activities.type5") },
            { key: 6, value: this.$t("Activities.type6") },
            { key: 7, value: this.$t("Activities.type7") },
            { key: 8, value: this.$t("Activities.type8") },
            { key: 9, value: this.$t("Activities.type9") },
            { key: 10, value: this.$t("Activities.type10") },
            { key: 11, value: this.$t("Activities.type11") },
            { key: 12, value: this.$t("Activities.type12") },
            { key: 13, value: this.$t("Activities.type13") },
            { key: 14, value: this.$t("Activities.type14") },
            { key: 15, value: this.$t("Activities.type15") },
            { key: 18, value: this.$t("Activities.type18") },
            { key: 19, value: this.$t("Activities.type19") },
            { key: 20, value: this.$t("Activities.type20") },
            { key: 21, value: this.$t("Activities.type21") },
            { key: 22, value: this.$t("Activities.type22") },
            { key: 23, value: this.$t("Activities.type23") },
            { key: 24, value: this.$t("Activities.type24") },
            { key: 25, value: this.$t("Activities.type25") },
            { key: 26, value: this.$t("Activities.type26") },
            { key: 27, value: this.$t("Activities.type27") },
            { key: 28, value: this.$t("Activities.type28") },
            { key: 29, value: this.$t("Activities.type29") },
            { key: 30, value: this.$t("Activities.type30") },
          ];

          if (load) {
            //数据拼接
            let list = JSON.parse(JSON.stringify(res.data.list));
            list.forEach((item) => {
              type.forEach((i) => {
                if (item.type == i.key) {
                  item.type = i.value;
                }
              });
            });
            this.mining_Record = [...this.mining_Record, ...list];
            //控制加载
            this.loading = false;
            if (!res.data.list.length) {
              this.finished = true;
            }
          } else {
            this.mining_Record = res.data.list;
            this.mining_Record.forEach((item) => {
              type.forEach((i) => {
                if (item.type == i.key) {
                  item.type = i.value;
                }
              });
            });
            this.refreshing = false;
          }
        });
    },
    //质押活动记录
    pledgeRecord(load = false) {
      this.$axios
        .get(this.$api.pledgeLisst, {
          page: this.objList[0].page,
          pageSize: 15,
          time: this.objList[0].time,
          operate: this.objList[0].operate,
          type: this.objList[0].type,
        })
        .then((res) => {
          let type = [
            //data中的数据流有问题 所以在这里重新定义一遍
            { key: 1, value: this.$t("Activities.type1") },
            { key: 2, value: this.$t("Activities.type2") },
            { key: 3, value: this.$t("Activities.type3") },
            { key: 4, value: this.$t("Activities.type4") },
            { key: 5, value: this.$t("Activities.type5") },
            { key: 6, value: this.$t("Activities.type6") },
            { key: 7, value: this.$t("Activities.type7") },
            { key: 8, value: this.$t("Activities.type8") },
            { key: 9, value: this.$t("Activities.type9") },
            { key: 10, value: this.$t("Activities.type10") },
            { key: 11, value: this.$t("Activities.type11") },
            { key: 12, value: this.$t("Activities.type12") },
            { key: 13, value: this.$t("Activities.type13") },
            { key: 14, value: this.$t("Activities.type14") },
            { key: 15, value: this.$t("Activities.type15") },
            { key: 18, value: this.$t("Activities.type18") },
            { key: 19, value: this.$t("Activities.type19") },
            { key: 20, value: this.$t("Activities.type20") },
            { key: 21, value: this.$t("Activities.type21") },
            { key: 22, value: this.$t("Activities.type22") },
            { key: 23, value: this.$t("Activities.type23") },
            { key: 24, value: this.$t("Activities.type24") },
            { key: 25, value: this.$t("Activities.type25") },
            { key: 26, value: this.$t("Activities.type26") },
            { key: 27, value: this.$t("Activities.type27") },
            { key: 28, value: this.$t("Activities.type28") },
            { key: 29, value: this.$t("Activities.type29") },
            { key: 30, value: this.$t("Activities.type30") },
          ];
          if (load) {
            //数据拼接
            let list = JSON.parse(JSON.stringify(res.data.list));
            list.forEach((item) => {
              type.forEach((i) => {
                if (item.type == i.key) {
                  item.type = i.value;
                }
              });
            });
            this.pledge_Recprd72h = [...this.pledge_Recprd72h, ...list];
            //控制加载
            this.loading = false;
            if (!res.data.list.length) {
              this.finished = true;
            }
          } else {
            this.pledge_Recprd72h = res.data.list;
            this.pledge_Recprd72h.forEach((item) => {
              type.forEach((i) => {
                if (item.type == i.key) {
                  item.type = i.value;
                }
              });
            });
            this.refreshing = false;
          }
        });
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    //答题筛选
    onAnswer(active, type, num) {
      this.objList[2].page = 1;
      if (type == "date") {
        this.active.answer_item[0].answerActive = active;
        this.objList[2].time = num;
      }
      if (type == "operate") {
        this.active.answer_item[1].answerActive = active;
        this.objList[2].operate = num;
      }
      if (type == "typesOf") {
        this.active.answer_item[2].answerActive = active;
        this.objList[2].typeT = num;
      }
    },
    //邀请筛选
    onAnsweryq(active, type, num) {
      this.objList[3].page = 1;
      if (type == "date") {
        this.active.inviter_item[0].inviterActive = active;
        this.objList[3].time = num;
      }
      if (type == "typesOf") {
        this.active.inviter_item[1].inviterActive = active;
        this.objList[3].operate = num;
      }
    },
    //挖矿筛选
    onMining(active, type, num) {
      this.objList[1].page = 1;
      if (type == "date") {
        this.active.mining_item[0].miningActive = active;
        this.objList[1].time = num;
      }
      if (type == "operate") {
        this.active.mining_item[1].miningActive = active;
        this.objList[1].operate = num;
      }
    },
    //质押筛选
    onPledge(active, type, num) {
      this.objList[0].page = 1;
      if (type == "date") {
        this.active.pledge_item[0].pledgeActive = active;
        this.objList[0].time = num;
      }
      if (type == "operate") {
        this.active.pledge_item[1].pledgeActive = active;
        this.objList[0].operate = num;
      }
      if (type == "typesOf") {
        this.active.pledge_item[2].pledgeActive = active;
        this.objList[0].type = num;
      }
    },
    back() {
      this.$router.back();
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    screenHandle(i, num) {
      this.currentScreen = i;
      this.i = num;
      this.finished = false;
    },
    //上拉加载事件
    onLoad() {
      this.finished = false;
      this.loading = true;
      const load = true;
      this.page++; //每次加载页数加1
      if (this.i == 1) {
        this.objList[0].page++;
        this.pledgeRecord(load);
      }
      if (this.i == 2) {
        this.objList[1].page++;
        this.wak(load);
      }
      if (this.i == 3) {
        this.objList[2].page++;
        this.answerRecord(load);
      }
      if (this.i == 4) {
        this.objList[3].page++;
        this.yqhd(load);
      }
    },
    // 下拉刷新
    onRefresh() {
      this.finished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      const load = false;
      if (this.i == 1) {
        // this.objList[0].page = 1;
        this.objList[0] = { page: 1, time: 0, operate: 0, type: 0 };
        this.pledgeRecord(load);
      }
      if (this.i == 2) {
        this.objList[1] = { page: 1, time: 0, operate: 0 };
        this.wak(load);
      }
      if (this.i == 3) {
        this.objList[2] = { page: 1, time: 0, operate: 0, typeT: 0 };
        this.answerRecord(load);
      }
      if (this.i == 4) {
        this.objList[3] = { page: 1, time: 0, operate: 0, typeT: 0 };
        this.yqhd(load);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 13px 15px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    > div:first-child {
      display: flex;
      align-items: center;
      span {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-left: 6px;
      }
    }
  }
  .list {
    .item {
      background: #f3f9ff;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 5px;
      .name {
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        .line {
          width: 3px;
          height: 10px;
          background: #3a8dff;
          border-radius: 2px;
        }
        > div:last-child {
          margin-left: 5px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
      }
      .time {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }
      .msg {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3a8dff;
        margin-bottom: 20px;
      }
    }
  }
}
.screen-popup {
  padding: 21px 15px;
  .title {
    text-align: center;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 33px;
  }
  .condition {
    display: flex;
    margin-bottom: 28px;
    flex-wrap: wrap;
    .currentScreen {
      color: #ffffff;
      background: #00a8ff;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24%;
      margin-right: 1.3%;
      font-size: 10px;
      font-family: Source Han Sans CN;
      margin-bottom: 10px;
      height: 30px;
      background: #f2f2f2;
      border-radius: 5px;
      font-weight: 400;
      color: #333333;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    button {
      width: 49%;
      &:first-child {
        margin-right: 2%;
      }
    }
  }
}
.btn {
  height: 40px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 20px 0;
  display: flex;
  div {
    width: 50%;
    height: 100%;
    color: #000;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    align-items: center;
  }
  .current {
    background: #8f92fa;
    color: #ffffff;
  }
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.record {
  width: 100%;
  height: 621px;
  border: 1px solid #000;
  border-radius: 15px;
  margin-top: 12px;
  padding: 10px 5px;
  .record_top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    div {
      //   width: 94px;
      flex: 0.3;
      text-align: left;
    }
  }
  .record_center {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000;
    div {
      //   width: 94px;
      flex: 0.3;
      text-align: left;
    }
    // div:nth-of-type(2) {
    //   text-align: center;
    // }
    // div:nth-of-type(3) {
    //   text-align: right;
    // }
  }
}
.activitiestext {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.pledge {
  .pledge_item {
    p {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      margin-bottom: 3px;
    }
    .pledge_tab {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;
      div {
        width: 106px;
        height: 37px;
        border: 1px solid #999999;
        border-radius: 5px;
        text-align: center;
        line-height: 37px;

        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .pledge_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    div {
      // width: 168px;
      flex: 0.48;
      height: 35px;
      border: 1px solid #000;
      border-radius: 5px;
      text-align: center;
      line-height: 35px;
    }
  }
}
.active {
  border: 1px solid #000;
  background: #ecedff;
  color: #333333 !important;
  font-weight: bold !important;
}
.mining {
  .mining_item {
    width: 100%;
    height: 71px;
    border: 1px solid #000;
    border-radius: 15px;
    padding: 18px 17px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .items {
      p {
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-bottom: 8px;
      }
      div {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
.hangbiaos {
  display: flex;
  color: #999999;
  font-size: 10px;
}
.biaosTow {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-left: 15px;
}
.biaos {
  width: 5px;
  height: 13px;
  border-radius: 5px;
  background: #8f92fa;
  margin-right: 7px;
}
</style>